import BaseLayout from '../components/layout/BaseLayout'
import ButtonLink from '../components/elements/buttons/ButtonLink'
import React from 'react'
import ShortContactForm from '../components/forms/ShortContactForm'
import { css } from '@emotion/react'
import makeSeoTitle from '../lib/utils/makeSeoTitle'

const notFoundContainer = css`
  position: relative;
  padding: 160px 0;
  background-image: url('/backgrounds/404.jpg');

  .dark-filter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.35);
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  div {
    position: relative;
    width: 60%;
    margin: auto;
    text-align: center;
    z-index: 10;
  }

  h1 {
    font-size: 4rem;
  }

  h1,
  p {
    color: #ffffff !important;
    text-shadow: 2px 2px 10px #000;
  }
`

const NotFoundPage = () => (
  <BaseLayout>
    <div css={notFoundContainer}>
      <div className="dark-filter" />
      <div>
        <h1>404</h1>
        <p>
          Entschuldigung! Die angefragte Seite konnte nicht gefunden werden.
        </p>
        <ShortContactForm />
        <ButtonLink
          to="/"
          variant="primary"
          large
          addShadow
          round
          css={css`
            margin-top: 50px;
          `}
        >
          Zurück zur Startseite
        </ButtonLink>
      </div>
    </div>
  </BaseLayout>
)

export const Head = () => <title>{makeSeoTitle('404: Nicht gefunden')}</title>

export default NotFoundPage
